<template>
  <div>
    <a-card :title="$t('盘点任务')">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />{{ $t("返回") }}</a-button
      >
      <a-spin :spinning="createLoading">
        <!-- <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider> -->

        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="stockCheckRecordMaterialItems"
            :loading="materialLoading"
            :pagination="false"
          >
            <div slot="actual_quantity" slot-scope="value, item, index">
              <a-input-number v-model="item.actual_quantity" size="small"> </a-input-number>
            </div>
            <div slot="stock_in_date" slot-scope="value, item, index">
              <a-date-picker v-model="item.stock_in_date" valueFormat="YYYY-MM-DD" size="small" />
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="createLoading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { stockCheckRecordCreate } from "@/api/stockCheck";
import { stockCheckMaterialOption } from "@/api/option";
import { columns } from "./columns";
import { rules } from "./rules";
import moment from "moment";

export default {
  data() {
    return {
      rules,
      moment,
      columns,
      form: {},
      stockChackOrder: undefined,
      stockCheckRecordMaterialItems: [],
      createLoading: false,
      materialLoading: false,
    };
  },
  methods: {
    initData() {
      this.resetForm();

      this.stockChackOrder = this.$route.query.stock_check_order;
      this.materialLoading = true;

      stockCheckMaterialOption({ stock_check_order: this.stockChackOrder, is_completed: false, page_size: 999999 })
        .then((data) => {
          for (let item of data.results) {
            this.stockCheckRecordMaterialItems.push({
              id: item.id,
              stock_check_material: item.id,
              location_number: item.location_number,
              batch_number: item.batch_number,
              material_name: item.material_name,
              material_number: item.material_number,
              book_quantity: item.book_quantity,
              actual_quantity: item.book_quantity,
              unit: item.unit,
              status_display: item.status_display,
              stock_in_date: item.stock_in_date,
            });
          }
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    removeMaterial(index) {
      let stockCheckRecordMaterialItems = [...this.stockCheckRecordMaterialItems];
      stockCheckRecordMaterialItems.splice(index, 1);
      this.stockCheckRecordMaterialItems = [...stockCheckRecordMaterialItems];
    },
    create() {
      if (this.stockCheckRecordMaterialItems.length == 0) {
        this.$message.warn(this.$t("未添加产品"));
        return;
      }

      let formData = {
        stock_check_order: this.stockChackOrder,
        stock_check_record_material_items: this.stockCheckRecordMaterialItems,
      };

      this.createLoading = true;
      stockCheckRecordCreate(formData)
        .then((data) => {
          this.$message.success(this.$t("创建成功"));
          this.$router.go(-1);

          // this.resetForm();
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    resetForm() {
      this.form = {};
      this.stockCheckRecordMaterialItems = [];
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
